import clsx from 'clsx';
import styled from 'types/AppTheme';
import { ITextButtonElementConfig } from 'api/digifi/portal-page-elements';
import { SystemButtonElement } from 'components/PortalPageElements';
import Header from 'product_modules/components/Header';
import { getBackgroundGradient } from 'components/PortalPageElements/utils/getBackgroundGradient';
import styles from './TextButtonElement.module.scss';

interface ITextButtonElementProps {
  config: ITextButtonElementConfig;
  containerClassName?: string;
}

const ContainerStyled = styled.div<{ disableGradient: boolean }>`
  ${({ disableGradient, theme }) => !disableGradient && `background: ${getBackgroundGradient(theme)};`}
`;

const TextButtonElement = ({ config, containerClassName }: ITextButtonElementProps) => {
  const hasNoText = !config.headerText && !config.bodyText;

  return (
    <ContainerStyled
      disableGradient={!config.applyBackgroundGradient}
      className={clsx(styles.contentContainer, hasNoText && styles.contentContainerWithoutText, containerClassName)}
    >
      {!hasNoText && (
        <div className={styles.textContainer}>
          {config.headerText && (
            <Header type="h3">
              {config.headerText}
            </Header>
          )}
          {config.bodyText && (
            <p className={styles.bodyText}>
              {config.bodyText}
            </p>
          )}
        </div>
      )}
      <div className={styles.systemButtonWrapper}>
        <SystemButtonElement config={config} />
      </div>
    </ContainerStyled>
  );
};

export default TextButtonElement;
