import React, { FC } from 'react';
import clsx from 'clsx';
import useTemplateRenderingContext from 'hooks/useTemplateRenderingContext';
import { ISystemVariableValue, VariableValue } from 'product_modules/api/Types';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import { ITextElementConfig } from 'api/digifi/portal-page-elements';
import renderStringTemplate from 'utils/renderStringTemplate';
import styles from './HeaderTextElement.module.scss';

interface IHeaderTextElementProps {
  config: ITextElementConfig;
  context?: Record<string, VariableValue | ISystemVariableValue>;
  containerClassName?: string;
}

const DEFAULT_HEADER_VALUE = 'Header Text';

const HeaderTextElement: FC<IHeaderTextElementProps> = ({
  config,
  context = {},
  containerClassName,
}) => {
  const templateRendingContext = useTemplateRenderingContext(config.text, context);

  if (!templateRendingContext) {
    return (
      <div className={containerClassName}>
        <SkeletonText lineHeight="44px" width="380px" height="36px" color="primary20" className={styles.skeletonDesktop} />
        <SkeletonText lineHeight="44px" width="320px" height="36px" color="primary20" className={styles.skeletonTablet} />
        <SkeletonText lineHeight="36px" width="240px" height="28px" color="primary20" className={styles.skeletonMobile} />
      </div>
    );
  }

  return (
    <div className={clsx(styles.headerText, containerClassName)} style={{ textAlign: config.alignment }}>
      {renderStringTemplate(config.text, templateRendingContext) || DEFAULT_HEADER_VALUE}
    </div>
  );
};

export default HeaderTextElement;
