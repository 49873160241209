import styled, { IAppTheme } from 'types/AppTheme';
import { rgba } from 'polished';

interface IContainerStyledProps {
  opacity?: number;
  color?: keyof IAppTheme['colors']
}

const ContainerStyled = styled.div<IContainerStyledProps>`
  background: ${({ theme, opacity, color = 'brandColor' }) => opacity 
    ? rgba(theme.colors[color], opacity) 
    : theme.colors[color]}
`;

export default ContainerStyled;
