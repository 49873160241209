import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { PlaidLogo } from 'static/images';
import BaseIntegrationElement from 'components/PortalPageElements/BaseIntegrationElement';
import { CheckedIcon } from 'product_modules/static/images';
import styles from './PlaidLinkElement.module.scss';

export interface IPlaidLinkElementProps {
  token: string | null;
  isLoading: boolean;
  disabled?: boolean;
  onConnect: () => void;
  onSuccess: (publicToken: string) => void;
  onExit: () => void;
}

const PlaidLinkElement = ({
  token,
  isLoading,
  disabled,
  onConnect,
  onSuccess,
  onExit,
}: IPlaidLinkElementProps) => {
  const [isConnectionInProgress, setIsConnectionInProgress] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSuccess = useCallback((publicToken: string) => {
    setIsConnectionInProgress(false);
    setIsButtonDisabled(true);

    onSuccess(publicToken);
  }, [setIsConnectionInProgress, setIsButtonDisabled, onSuccess]);

  const handleExit = useCallback(() => {
    setIsConnectionInProgress(false);

    onExit();
  }, [setIsConnectionInProgress, onExit]);

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: handleSuccess,
    onExit: handleExit,
  });

  useEffect(() => {
    if (!token || !ready) {
      return;
    }

    setIsConnectionInProgress(true);

    open();
  }, [token, ready, open]);

  const buttonTitle = isButtonDisabled
    ? <div className={styles.buttonTitle}><CheckedIcon /> Connected</div>
    : <>Connect</>;

  return (
    <BaseIntegrationElement
      title='Connect Account'
      description='Connect your financial account to securely verify your information.'
      logo={<PlaidLogo />}
      buttonTitle={buttonTitle}
      buttonTooltip={disabled ? 'Connection is currently unavailable.' : ''}
      disabled={disabled || isButtonDisabled}
      isLoading={isLoading || isConnectionInProgress}
      onButtonClick={onConnect}
    />
  );
};

export default PlaidLinkElement;
