import React, { FC, FocusEvent } from 'react';
import { IPageElementConfig, IPortalPageElement } from 'api/digifi/PortalPageElementsApi';
import { useAppSelector } from 'hooks/reduxHooks';
import {
  BodyTextElement,
  ButtonElement,
  DataInputElement,
  ESignatureElement,
  HeaderTextElement,
  ImageElement,
  OfferSelectionElement,
  PageDividerElement,
  ProgressBarElement,
  SubHeaderTextElement,
  TasksElement,
  TextColumnsElement,
  PlaidLinkElement,
} from 'components/PortalPageElements';
import {
  ITextElementConfig,
  PortalPageElementType,
  IPageDividerElementConfig,
  IImageElementConfig,
  ITasksElementConfig,
  IOfferSelectionElementConfig,
  IDataInputElementConfig,
  IButtonElementConfig,
  IProgressBarElementConfig,
  IESignatureElementConfig,
  ITextColumnsElementConfig,
  IOfferSelectionPageElement,
  IPlaidLinkElementConfig, IESignatureElement,
} from 'api/digifi/portal-page-elements';
import { pageElementMargins } from 'components/ApplicationPage/PageElement/utils/elementMargins';
import { ISystemVariableValue, TableValue, VariableValue } from 'product_modules/api/Types';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';

export interface IPageElementProps<ConfigType extends IPageElementConfig> {
  config: ConfigType;
  applicationDisplayId: string;
  productId: string;
  elementId: string;
  context?: Record<string, VariableValue | ISystemVariableValue>;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur: (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onSubmit: () => Promise<void>;
  onOfferSelect: (updatedOffers: TableValue) => void;
  offersVariableValue: TableValue | null;
  offersSelectionElement: IOfferSelectionPageElement | null;
  eSignatureElement: IESignatureElement | null;
  containerClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  isSubmitInProgress?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
  imageUrls?: Record<string, string>;
}

interface IPortalPageElementMapType {
  [PortalPageElementType.HeaderText]: FC<IPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.SubHeaderText]: FC<IPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.BodyText]: FC<IPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.PageDivider]: FC<IPageElementProps<IPageDividerElementConfig>>;
  [PortalPageElementType.Tasks]: FC<IPageElementProps<ITasksElementConfig>>;
  [PortalPageElementType.OfferSelection]: FC<IPageElementProps<IOfferSelectionElementConfig>>;
  [PortalPageElementType.Image]: FC<IPageElementProps<IImageElementConfig>>;
  [PortalPageElementType.Button]: FC<IPageElementProps<IButtonElementConfig>>;
  [PortalPageElementType.DataInput]: FC<IPageElementProps<IDataInputElementConfig>>;
  [PortalPageElementType.ProgressBar]: FC<IPageElementProps<IProgressBarElementConfig>>;
  [PortalPageElementType.ESignature]: FC<IPageElementProps<IESignatureElementConfig>>;
  [PortalPageElementType.TextColumns]: FC<IPageElementProps<ITextColumnsElementConfig>>;
  [PortalPageElementType.PlaidLink]: FC<IPageElementProps<IPlaidLinkElementConfig>>;
}

const portalPageElementByType: IPortalPageElementMapType = {
  [PortalPageElementType.HeaderText]: HeaderTextElement,
  [PortalPageElementType.SubHeaderText]: SubHeaderTextElement,
  [PortalPageElementType.BodyText]: BodyTextElement,
  [PortalPageElementType.PageDivider]: PageDividerElement,
  [PortalPageElementType.Tasks]: TasksElement,
  [PortalPageElementType.OfferSelection]: OfferSelectionElement,
  [PortalPageElementType.Image]: ImageElement,
  [PortalPageElementType.Button]: ButtonElement,
  [PortalPageElementType.DataInput]: DataInputElement,
  [PortalPageElementType.ProgressBar]: ProgressBarElement,
  [PortalPageElementType.ESignature]: ESignatureElement,
  [PortalPageElementType.TextColumns]: TextColumnsElement,
  [PortalPageElementType.PlaidLink]: PlaidLinkElement,
};

interface IPageElementComponentProps {
  applicationDisplayId: string;
  productId: string;
  element: IPortalPageElement;
  context: Record<string, VariableValue | ISystemVariableValue>;
  isElementLoadingByType: Record<PortalPageElementType, boolean>;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur: (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onSubmitPageData: () => Promise<void>;
  offersVariableValue: TableValue | null;
  onOfferSelect: (updatedOffers: TableValue) => void;
  offersSelectionElement: IOfferSelectionPageElement | null;
  eSignatureElement: IESignatureElement | null;
  containerRef?: React.Ref<HTMLDivElement>;
  imageUrls?: Record<string, string>;
}

const PageElement: FC<IPageElementComponentProps> = ({
  applicationDisplayId,
  productId,
  element,
  context,
  isElementLoadingByType,
  portalPageFormData,
  onPortalPageFieldChange,
  onPortalPageFieldBlur,
  onSubmitPageData,
  offersVariableValue,
  onOfferSelect,
  offersSelectionElement,
  eSignatureElement,
  containerRef,
  imageUrls,
}) => {
  const isSubmitInProgress = useAppSelector((state) => state.applicationData.isSubmitInProgress);

  const Element = portalPageElementByType[element.elementType] as FC<IPageElementProps<IPageElementConfig>>;
  const className = pageElementMargins[element.elementType];

  return (
    <Element
      applicationDisplayId={applicationDisplayId}
      productId={productId}
      config={element.config}
      elementId={element.id}
      context={context}
      containerClassName={className}
      loading={isElementLoadingByType[element.elementType]}
      portalPageFormData={portalPageFormData}
      onPortalPageFieldChange={onPortalPageFieldChange}
      onPortalPageFieldBlur={onPortalPageFieldBlur}
      onSubmit={onSubmitPageData}
      disabled={isSubmitInProgress}
      isSubmitInProgress={isSubmitInProgress}
      offersVariableValue={offersVariableValue}
      onOfferSelect={onOfferSelect}
      offersSelectionElement={offersSelectionElement}
      eSignatureElement={eSignatureElement}
      containerRef={containerRef}
      imageUrls={imageUrls}
    />
  );
};

export default PageElement;
