import { PortalPageElementType } from 'api/digifi/portal-page-elements';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';

const findPortalPageElementByType = <Type extends IPortalPageElement>(
  elements: IPortalPageElement[],
  elementType: PortalPageElementType,
): Type | null => {
  return (elements.find((element) => element?.elementType === elementType) || null) as Type | null;
};

export default findPortalPageElementByType;
