import { PortalPageElementType } from 'api/digifi/portal-page-elements';
import styles from './ElementMargins.module.scss';

export const pageElementMargins = {
  [PortalPageElementType.HeaderText]: styles.headerText,
  [PortalPageElementType.SubHeaderText]: styles.subHeaderText,
  [PortalPageElementType.BodyText]: styles.bodyText,
  [PortalPageElementType.Image]: styles.image,
  [PortalPageElementType.PageDivider]: styles.pageDivider,
  [PortalPageElementType.DataInput]: styles.dataInput,
  [PortalPageElementType.Tasks]: styles.tasks,
  [PortalPageElementType.OfferSelection]: styles.offerSelection,
  [PortalPageElementType.Button]: styles.button,
  [PortalPageElementType.ProgressBar]: styles.progressBar,
  [PortalPageElementType.ESignature]: styles.eSignature,
  [PortalPageElementType.PlaidLink]: styles.plaidLink,
  [PortalPageElementType.TextColumns]: styles.textColumns,
};
