export enum PortalPageElementType {
  HeaderText = 'HeaderText',
  SubHeaderText = 'SubHeaderText',
  BodyText = 'BodyText',
  OfferSelection = 'OfferSelection',
  Tasks = 'Tasks',
  DataInput = 'DataInput',
  Button = 'Button',
  Image = 'Image',
  PageDivider = 'PageDivider',
  ProgressBar = 'ProgressBar',
  ESignature = 'ESignature',
  PlaidLink = 'PlaidLink',
  TextColumns = 'TextColumns',
  TextButton = 'TextButton',
  SystemButton = 'SystemButton',
}

export enum ElementAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface ITextElementConfig {
  text: string;
  alignment: ElementAlignment;
}

export enum SystemButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum SystemButtonUrl {
  ApplyNow = 'applyNow',
  CreateAccount = 'createAccount',
  SignIn = 'signIn',
  Custom = 'custom',
}

export interface IBasePortalPageElement<ElementType extends PortalPageElementType = PortalPageElementType> {
  id: string;
  name: string;
  portalPageId: string;
  position: number;
  elementType: ElementType;
}
