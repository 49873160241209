import { useEffect, useCallback, useState, FC } from 'react';
import clsx from 'clsx';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import { useGetDocuSignIntegrationDataQuery } from 'slices';
import NoDocumentsToSign from './NoDocumentsToSign';
import WaitingForOthers from './WaitingForOthers';
import FailedToLoadDocument from './FailedToLoadDocument';
import useDocuSignWidget from './useDocuSignWidget';
import SessionTimeout from './SessionTimeout';
import styles from './ESignatureElement.module.scss';

interface IESignatureElementProps {
  applicationDisplayId: string;
  containerClassName?: string;
}

const MOUNT_CONTAINER_ID = 'agreementContainer';

const ESignatureElement: FC<IESignatureElementProps> = ({ applicationDisplayId, containerClassName }) => {
  const [isError, setIsError] = useState(false);
  const [isSessionTimeout, setIsSessionTimeout] = useState(false);

  const {
    currentData: docuSignIntegrationData,
    isLoading: isDocuSignIntegrationDataLoading,
    refetch: handleDocuSignIntegrationDataRefetch,
    isFetching: isDocuSignIntegrationDataFetching,
    error: getDocuSignIntegrationDataError,
  } = useGetDocuSignIntegrationDataQuery(applicationDisplayId, {
    refetchOnMountOrArgChange: true,
  });

  const isLoading = isDocuSignIntegrationDataLoading || isDocuSignIntegrationDataFetching;
  const showWidget = !isLoading && docuSignIntegrationData?.embeddedSigningUrl && docuSignIntegrationData?.clientId;
  const showNoDocumentsToSign = docuSignIntegrationData === null
    || (docuSignIntegrationData && !docuSignIntegrationData.embeddedSigningUrl);
  const showWaitingForOthers = docuSignIntegrationData && docuSignIntegrationData.waitingForOthers;

  const handleError = useCallback(() => {
    setIsError(true);
  }, [docuSignIntegrationData?.embeddedSigningUrl]);

  const handleSessionEnd = useCallback((event) => {
    switch (event.sessionEndType) {
      case 'signing_complete':
      case 'decline':
      case 'viewing_complete':
        return handleDocuSignIntegrationDataRefetch();
      case 'session_timeout':
        return setIsSessionTimeout(true);
      default:
        return handleError();
    }
  }, [docuSignIntegrationData]);

  const handleRetry = useCallback(() => {
    setIsError(false);
    setIsSessionTimeout(false);
    handleDocuSignIntegrationDataRefetch();
  }, [handleDocuSignIntegrationDataRefetch]);

  const loadDocuSignWidget = useDocuSignWidget({
    bundleUrl: docuSignIntegrationData?.bundleUrl || '',
    onSessionEnd: handleSessionEnd,
    onError: handleError,
    mountContainerId: MOUNT_CONTAINER_ID,
  });

  useEffect(() => {
    if (docuSignIntegrationData?.embeddedSigningUrl && docuSignIntegrationData?.clientId) {
      loadDocuSignWidget(docuSignIntegrationData.clientId, docuSignIntegrationData.embeddedSigningUrl);
    }
  }, [loadDocuSignWidget, docuSignIntegrationData]);

  useEffect(() => {
    if (getDocuSignIntegrationDataError) {
      handleError();
    }
  }, [getDocuSignIntegrationDataError, handleError]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <LoaderOverlay loaderClassName={styles.loader}/>
        </div>
      );
    }

    if (showWaitingForOthers) {
      return <WaitingForOthers />;
    }

    if (!showWaitingForOthers && showNoDocumentsToSign) {
      return <NoDocumentsToSign />;
    }

    if (isError) {
      return <FailedToLoadDocument onClick={handleRetry} />;
    }

    if (isSessionTimeout) {
      return <SessionTimeout onClick={handleRetry} />;
    }

    return (
      <div
        id={MOUNT_CONTAINER_ID}
        className={clsx(styles.embeddedSigningContainer, showWidget && styles.showEmbeddedSigningContainer)}
      />
    );
  };

  return (
    <div className={containerClassName}>{renderContent()}</div>
  );
};

export default ESignatureElement;
