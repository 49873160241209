import { TextColumnsBulletType } from 'api/digifi/portal-page-elements';
import { CheckedImage } from 'static/images';
import ContainerStyled from 'components/BrandingHelpers/ContainerStyled';
import styles from './TextColumnsElement.module.scss';

interface INumberBullet {
  index: number;
  bulletType: TextColumnsBulletType;
}

const CONTAINER_BACKGROUND_OPACITY = 0.2;

const Bullet = ({ index, bulletType }: INumberBullet) => {
  const renderBulletContent = () => {
    switch (bulletType) {
      case TextColumnsBulletType.Numbered:
        return index + 1;
      case TextColumnsBulletType.Checked:
        return <CheckedImage className={styles.checkedIcon} />;
      case TextColumnsBulletType.Simple:
        return '●';
      default:
        return null;
    }
  };

  const renderBullet = () => {
    if (bulletType === TextColumnsBulletType.NoBullet) {
      return null;
    }

    return (
      <ContainerStyled className={styles.bulletContainer} opacity={CONTAINER_BACKGROUND_OPACITY}>
        <ContainerStyled className={styles.bulletContent}>
          {renderBulletContent()}
        </ContainerStyled>
      </ContainerStyled>
    );
  };

  return (
    <>{renderBullet()}</>
  );
};

export default Bullet;
