import BaseIntegrationElement from 'components/PortalPageElements/BaseIntegrationElement';
import { DocuSignLogo } from 'static/images';

const WaitingForOthers = () => {
  return (
    <BaseIntegrationElement
      logo={<DocuSignLogo />}
      title="Waiting for Others"
      description="The signing process must be completed by the other signers to proceed."
    />
  );
};

export default WaitingForOthers;
