import { IBasePortalPageElement, PortalPageElementType } from './BasePortalPageElement';

export enum TextColumnsBulletType {
  Simple = 'simple',
  Numbered = 'numbered',
  Checked = 'checked',
  NoBullet = 'noBullet'
}

export interface ITextColumnParams {
  headerText?: string;
  bodyText?: string;
}

export interface ITextColumnsElementConfig {
  bulletType: TextColumnsBulletType;
  columns: ITextColumnParams[];
}

export interface ITextColumnsElement extends IBasePortalPageElement {
  elementType: PortalPageElementType.TextColumns;
  config: ITextColumnsElementConfig;
}
