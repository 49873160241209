import {
  IHeaderTextPageElement,
  ISubHeaderTextPageElement,
  IBodyTextPageElement,
  IPageDividerElementConfig,
  IPageDividerPageElement,
  ITasksElementConfig,
  ITasksPageElement,
  IOfferSelectionElementConfig,
  IOfferSelectionPageElement,
  IImageElementConfig,
  IImagePageElement,
  IButtonElementConfig,
  IButtonPageElement,
  ITextElementConfig,
  IDataInputPageElement,
  IDataInputElementConfig,
  IProgressBarPageElement,
  IProgressBarElementConfig,
  IESignatureElement,
  IESignatureElementConfig,
  ITextColumnsElement,
  ITextColumnsElementConfig,
  PortalPageElementType,
  IPlaidLinkPageElement,
  IPlaidLinkElementConfig,
} from './portal-page-elements';
import BasePortalPageElementsRestApi, {
  IPortalPageElementsApi,
} from './portal-page-elements/BasePortalPageElementsApi';

export type ITextPageElement =
  | IHeaderTextPageElement
  | ISubHeaderTextPageElement
  | IBodyTextPageElement;

export type IPortalPageElement =
  | ITextPageElement
  | IPageDividerPageElement
  | ITasksPageElement
  | IOfferSelectionPageElement
  | IImagePageElement
  | IButtonPageElement
  | IDataInputPageElement
  | IProgressBarPageElement
  | IESignatureElement
  | ITextColumnsElement
  | IPlaidLinkPageElement;

export type IPageElementConfig =
  | ITextElementConfig
  | IPageDividerElementConfig
  | ITasksElementConfig
  | IOfferSelectionElementConfig
  | IImageElementConfig
  | IButtonElementConfig
  | IDataInputElementConfig
  | IProgressBarElementConfig
  | IESignatureElementConfig
  | ITextColumnsElementConfig
  | IPlaidLinkElementConfig;

export interface IFindPortalPageElementsParams {
  statusId: string;
}

export default class PortalPageElementsRestApi
  extends BasePortalPageElementsRestApi<PortalPageElementType, IPortalPageElement, IFindPortalPageElementsParams>
  implements IPortalPageElementsApi<
    IPortalPageElement,
    IFindPortalPageElementsParams
  > {
  protected resourceName = 'portal-page-elements';
}
