import BaseIntegrationElement from 'components/PortalPageElements/BaseIntegrationElement';
import { DocuSignLogo } from 'static/images';

const NoDocumentsToSign = () => {
  return (
    <BaseIntegrationElement
      logo={<DocuSignLogo />}
      title="No Documents to Sign"
      description="There are currently no documents that require your review and signature."
    />
  );
};

export default NoDocumentsToSign;
