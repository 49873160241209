import { PortalPageElementType } from 'api/digifi/portal-page-elements';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';
import { IPortalLandingPageElement } from 'api/digifi/PortalLandingPageElementsApi';

export const filterImageElementIds = (elements: IPortalPageElement[] | IPortalLandingPageElement[]) => {
  return Object.values(elements)
    .filter((element) => element && element.elementType === PortalPageElementType.Image && !!element.config.imageParams)
    .map((element) => element!.id);
};

