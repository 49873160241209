import { FC } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import {
  BodyTextElement,
  ImageElement,
  HeaderTextElement,
  PageDividerElement,
  SubHeaderTextElement,
  TextColumnsElement,
  TextButtonElement,
  SystemButtonElement,
} from 'components/PortalPageElements';
import {
  ITextElementConfig,
  PortalPageElementType,
  IPageDividerElementConfig,
  IImageElementConfig,
  ITextColumnsElementConfig,
  ITextButtonElementConfig,
  ISystemButtonElementConfig,
} from 'api/digifi/portal-page-elements';
import { landingPageElementMargins } from 'components/GuestHome/utils/landingPageElementMargins';
import {
  IPortalLandingPageElement,
  IPortalLandingPageElementConfig,
} from 'api/digifi/PortalLandingPageElementsApi';

export interface ILandingPageElementProps<ConfigType extends IPortalLandingPageElementConfig> {
  config: ConfigType;
  elementId: string;
  containerClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  isSubmitInProgress?: boolean;
  imageUrls?: Record<string, string>;
}

interface IPortalLandingPageElementMapType {
  [PortalPageElementType.HeaderText]: FC<ILandingPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.SubHeaderText]: FC<ILandingPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.BodyText]: FC<ILandingPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.PageDivider]: FC<ILandingPageElementProps<IPageDividerElementConfig>>;
  [PortalPageElementType.Image]: FC<ILandingPageElementProps<IImageElementConfig>>;
  [PortalPageElementType.TextColumns]: FC<ILandingPageElementProps<ITextColumnsElementConfig>>;
  [PortalPageElementType.TextButton]: FC<ILandingPageElementProps<ITextButtonElementConfig>>;
  [PortalPageElementType.SystemButton]: FC<ILandingPageElementProps<ISystemButtonElementConfig>>;
}

const portalLandingPageElementByType: IPortalLandingPageElementMapType = {
  [PortalPageElementType.HeaderText]: HeaderTextElement,
  [PortalPageElementType.SubHeaderText]: SubHeaderTextElement,
  [PortalPageElementType.BodyText]: BodyTextElement,
  [PortalPageElementType.PageDivider]: PageDividerElement,
  [PortalPageElementType.Image]: ImageElement,
  [PortalPageElementType.TextColumns]: TextColumnsElement,
  [PortalPageElementType.TextButton]: TextButtonElement,
  [PortalPageElementType.SystemButton]: SystemButtonElement,
};

interface ILandingPageElementComponentProps {
  element: IPortalLandingPageElement;
  isElementLoadingByType: Record<PortalPageElementType, boolean>;
  imageUrls?: Record<string, string>;
}

const LandingPageElement = ({
  element,
  isElementLoadingByType,
  imageUrls,
}: ILandingPageElementComponentProps) => {
  const isSubmitInProgress = useAppSelector((state) => state.applicationData.isSubmitInProgress);

  const Element = portalLandingPageElementByType[element.elementType] as FC<ILandingPageElementProps<IPortalLandingPageElementConfig>>;
  const className = landingPageElementMargins[element.elementType];

  return (
    <Element
      config={element.config}
      elementId={element.id}
      containerClassName={className}
      loading={isElementLoadingByType[element.elementType]}
      disabled={isSubmitInProgress}
      isSubmitInProgress={isSubmitInProgress}
      imageUrls={imageUrls}
    />
  );
};

export default LandingPageElement;
