import { useNavigate } from 'react-router-dom';
import { ISystemButtonElementConfig, SystemButtonUrl } from 'api/digifi/portal-page-elements';
import Button from 'components/digifi-wrappers/Button';
import { AppRoute } from 'enums/AppRoute';
import styles from './SystemButtonElement.module.scss';

const DEFAULT_BUTTON_TEXT = 'Button';

interface ISystemButtonElementProps {
  config: ISystemButtonElementConfig;
  containerClassName?: string;
}

const SystemButtonElement = ({ config, containerClassName }: ISystemButtonElementProps) => {
  const navigate = useNavigate();

  const handleNavigate = (link: string) => {
    if (config.openInNewTab) {
      return window.open(link, '_blank');
    }

    if (config.buttonUrl === SystemButtonUrl.Custom) {
      return window.open(link, '_self');
    }

    return navigate(link);
  };

  const handleButtonClick = () => {
    switch (config.buttonUrl) {
      case SystemButtonUrl.ApplyNow:
        return handleNavigate(AppRoute.Start);
      case SystemButtonUrl.SignIn:
        return handleNavigate(AppRoute.SignIn);
      case SystemButtonUrl.CreateAccount:
        return handleNavigate(AppRoute.CreateAccount);
      case SystemButtonUrl.Custom:
        return handleNavigate(config.customUrl || '');
      default:
        return null as never;
    }
  };

  return (
    <div className={containerClassName} style={{ textAlign: config.alignment }}>
      <Button
        onClick={handleButtonClick}
        size="form"
        kind={config.buttonType || 'primary'}
        className={styles.button}
      >
        {config.buttonText || DEFAULT_BUTTON_TEXT}
      </Button>
    </div>
  );
};

export default SystemButtonElement;
