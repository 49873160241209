import { FC } from 'react';
import clsx from 'clsx';
import BorrowerTypeToggleGroup, { IBorrowerTypeToggleGroupProps } from 'product_modules/components/BorrowerTypeToggleGroup';
import styled from 'types/AppTheme';
import styles from './BorrowerTypeToggleGroup.module.scss';

const TOGGLE_BUTTON_CLASS_NAME = 'TOGGLE_BUTTON_CLASS_NAME';
const SELECTED_TOGGLE_BUTTON_CLASS_NAME = 'SELECTED_TOGGLE_BUTTON_CLASS_NAME';

const BorrowerTypeToggleGroupStyled = styled(BorrowerTypeToggleGroup)<IBorrowerTypeToggleGroupProps>`
  .${TOGGLE_BUTTON_CLASS_NAME} {
    height: auto;
    min-height: 44px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundHoverAccentColor};
      border-color: ${({ theme }) => theme.colors.accentColor};
      color: ${({ theme }) => theme.colors.accentColor};
    }
  }

  .${SELECTED_TOGGLE_BUTTON_CLASS_NAME} {
    background-color: ${({ theme }) => theme.colors.accentColor};
    border-color: ${({ theme }) => theme.colors.accentColor};

    &:focus, &:hover {
      background-color: ${({ theme }) => theme.colors.accentColor};
      border-color: ${({ theme }) => theme.colors.accentColor};
      color: #fff;
    }
  }
`;

const BorrowerTypeToggleGroupWrapper: FC<IBorrowerTypeToggleGroupProps> = (props) => {
  return (
    <BorrowerTypeToggleGroupStyled
      {...props}
      selectedButtonClassName={SELECTED_TOGGLE_BUTTON_CLASS_NAME}
      buttonClassName={clsx(styles.toggleButton, TOGGLE_BUTTON_CLASS_NAME)}
    />
  );
};

export default BorrowerTypeToggleGroupWrapper;
