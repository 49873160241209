import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import {
  useGetPortalLandingPageElementsQuery,
  useGenerateLandingPageImageUrlsMutation,
} from 'slices';
import LoaderOverlay from 'components/PageLayout/LoaderOverlay';
import LandingPageElement from 'components/GuestHome/LandingPageElement';
import useImageUrls, { GenerateImageUrls } from 'components/PortalPageElements/hooks/useImageUrls';
import { PortalPageElementType } from 'api/digifi/portal-page-elements';
import styles from './StartPage.module.scss';

const StartPage = () => {
  const {
    currentData: landingPageElements,
    isLoading: isLandingPageElementsLoading,
  } = useGetPortalLandingPageElementsQuery();

  const [generateImageUrls] = useGenerateLandingPageImageUrlsMutation();

  const imageUrls = useImageUrls(
    landingPageElements,
    generateImageUrls as GenerateImageUrls,
  );

  if (!landingPageElements || isLandingPageElementsLoading) {
    return <LoaderOverlay />;
  }

  return (
    <UnauthorizedLayout>
      <div className={styles.container}>
        {landingPageElements.map((element) => (
          <LandingPageElement
            key={element.id}
            element={element}
            isElementLoadingByType={{} as Record<PortalPageElementType, boolean>}
            imageUrls={imageUrls}
          />
        ))}
      </div>
    </UnauthorizedLayout>
  );
};

export default StartPage;
