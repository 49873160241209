import { PortalPageElementType } from 'api/digifi/portal-page-elements';
import styles from './LandingPageElementMargins.module.scss';

export const landingPageElementMargins = {
  [PortalPageElementType.HeaderText]: styles.headerText,
  [PortalPageElementType.SubHeaderText]: styles.subHeaderText,
  [PortalPageElementType.BodyText]: styles.bodyText,
  [PortalPageElementType.Image]: styles.image,
  [PortalPageElementType.PageDivider]: styles.pageDivider,
  [PortalPageElementType.TextColumns]: styles.textColumns,
  [PortalPageElementType.TextButton]: styles.textButton,
  [PortalPageElementType.SystemButton]: styles.button,
};
