import React, { FC } from 'react';
import clsx from 'clsx';
import { IImageElementConfig, ImageElementAlignment } from 'api/digifi/portal-page-elements';
import { ImageStubIcon } from 'static/images';
import SkeletonImage from './SkeletonImage';
import styles from './ImageElement.module.scss';

interface IImageElementProps {
  config: IImageElementConfig;
  elementId: string;
  containerClassName?: string;
  imageUrls?: Record<string, string>;
}

const getAlignmentClassName = (alignment: ImageElementAlignment) => {
  switch (alignment) {
    case ImageElementAlignment.Left:
      return styles.skeletonLeftAlignment;
    case ImageElementAlignment.Right:
      return styles.skeletonRightAlignment;
    default:
      return styles.skeletonCenterAlignment;
  }
};

const ImageElement: FC<IImageElementProps> = ({
  config,
  elementId,
  containerClassName,
  imageUrls,
}) => {
  const imageSrc = imageUrls?.[elementId] || '';

  if (!config.imageParams)  {
    return (
      <div className={clsx(styles.stubContainer, containerClassName)}>
        <ImageStubIcon/>
      </div>
    );
  }

  if (!imageSrc) {
    return (
      <div className={clsx(styles.skeletonImage, getAlignmentClassName(config.alignment), containerClassName)}>
        <SkeletonImage imageParams={config.imageParams} />
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.imageContainer, getAlignmentClassName(config.alignment), containerClassName)}
    >
      <img
        src={imageSrc}
        alt="Image"
        className={clsx(styles.image, config.alignment === ImageElementAlignment.Fill && styles.fullWidthImage)}
      />
    </div>
  );
};

export default ImageElement;
