import React, { FC } from 'react';
import clsx from 'clsx';
import { useResizeDetector } from 'react-resize-detector';
import Separator from 'components/digifi-wrappers/Separator';
import Ellipses from 'components/PortalPageElements/ProgressBarElement/Ellipses';
import { IProgressBarElementConfig } from 'api/digifi/portal-page-elements';
import { CheckedImage } from 'static/images';
import getArrayOfStepsToRender from './utils';
import styles from './ProgressBarElement.module.scss';

export interface IProgressBarElementProps {
  config: IProgressBarElementConfig;
  progressStepActiveClassName?: string;
  progressStepDoneClassName?: string;
  checkMarkClassName?: string;
  separatorDoneClassName?: string;
  className?: string;
  containerClassName?: string;
  containerRef?: React.Ref<HTMLDivElement>;
}

interface IRenderStepProps {
  stepNumber: number;
  isActive: boolean;
  isDone: boolean;
  showSeparator: boolean;
  showEllipses: boolean;
}

const MAX_NUMBER_OF_STEPS = 15;
const MIN_SEPARATOR_WIDTH = 24;
const STEP_WIDTH = 36;

const ProgressBarElement: FC<IProgressBarElementProps> = ({
  config,
  progressStepActiveClassName,
  progressStepDoneClassName,
  separatorDoneClassName,
  checkMarkClassName,
  className,
  containerClassName,
  containerRef,
}) => {
  const { width: containerWidth } = useResizeDetector({ targetRef: containerRef as React.MutableRefObject<HTMLDivElement> });

  const maxAmountOfStepsToRender = !containerWidth
    ? MAX_NUMBER_OF_STEPS
    : Math.floor(containerWidth / (STEP_WIDTH + MIN_SEPARATOR_WIDTH));

  const renderSeparator = (showSeparator: boolean, showEllipses: boolean, isDone: boolean) => {
    if (!showSeparator) {
      return;
    }

    return showEllipses ? (
      <Ellipses ellipseClassName={clsx(isDone && separatorDoneClassName)} />
    ) : (
      <Separator className={clsx(
        styles.separator,
        isDone && styles.separatorDone,
        isDone && separatorDoneClassName,
      )}/>
    );
  };

  const renderStep = ({ stepNumber, isActive, isDone, showSeparator, showEllipses }: IRenderStepProps) => {
    return (
      <React.Fragment key={stepNumber}>
        <div className={clsx(
          styles.progressStep,
          isActive && styles.progressStepActive,
          isActive && progressStepActiveClassName,
          isDone && styles.progressStepDone,
          isDone && progressStepDoneClassName,
        )}>
          {!isDone ? stepNumber : <CheckedImage className={checkMarkClassName}/>}
        </div>
        {renderSeparator(showSeparator, showEllipses, isDone)}
      </React.Fragment>
    );
  };

  const renderProgressBar = (maxStepsShown: number) => {
    const arrayOfRenderedSteps = getArrayOfStepsToRender(config.numberOfSteps, config.activeStep || 1, maxStepsShown);

    return arrayOfRenderedSteps.map((value, index) => renderStep({
      stepNumber: value,
      isActive: value === config.activeStep,
      isDone: value < (config.activeStep || 0),
      showSeparator: value !== config.numberOfSteps,
      showEllipses: index !== arrayOfRenderedSteps.length - 1 && value + 1 !== arrayOfRenderedSteps[index + 1],
    }));
  };

  return (
    <div className={clsx(styles.container, containerClassName, className)}>
      {renderProgressBar(maxAmountOfStepsToRender)}
    </div>
  );
};

export default ProgressBarElement;
