import {
  IHeaderTextPageElement,
  ISubHeaderTextPageElement,
  IBodyTextPageElement,
  IPageDividerElementConfig,
  IPageDividerPageElement,
  IImageElementConfig,
  IImagePageElement,
  ITextElementConfig,
  ITextColumnsElement,
  ITextColumnsElementConfig,
  PortalPageElementType,
  ITextButtonElement,
  ITextButtonElementConfig,
  ISystemButtonPageElement,
  ISystemButtonElementConfig,
} from './portal-page-elements';
import BasePortalPageElementsRestApi, {
  IPortalPageElementsApi,
} from './portal-page-elements/BasePortalPageElementsApi';

export type ITextPageElement =
  | IHeaderTextPageElement
  | ISubHeaderTextPageElement
  | IBodyTextPageElement;

export type IPortalLandingPageElement =
  | ITextPageElement
  | IPageDividerPageElement
  | IImagePageElement
  | ITextColumnsElement
  | ITextButtonElement
  | ISystemButtonPageElement;

export type IPortalLandingPageElementConfig =
  | ITextElementConfig
  | IPageDividerElementConfig
  | IImageElementConfig
  | ITextColumnsElementConfig
  | ITextButtonElementConfig
  | ISystemButtonElementConfig;

export default class PortalLandingPageElementsRestApi
  extends BasePortalPageElementsRestApi<PortalPageElementType, IPortalLandingPageElement>
  implements IPortalPageElementsApi<IPortalLandingPageElement> {
  protected resourceName = 'portal-landing-page-elements';
}
