import { useCallback, useEffect, useMemo, useState } from 'react';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';
import { filterImageElementIds } from 'components/PortalPageElements/utils/filterImageElementIds';
import { IGenerateImageUrlsParams } from 'api/digifi/portal-page-elements/BasePortalPageElementsApi';
import { IPortalLandingPageElement } from 'api/digifi/PortalLandingPageElementsApi';

export type GenerateImageUrls = (params: IGenerateImageUrlsParams) => Promise<{ data: Record<string, string> }>;

const useImageUrls = (
  elements: IPortalPageElement[] | IPortalLandingPageElement[] | undefined,
  generateImageUrls: GenerateImageUrls,
) => {
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  const imageElementIds = useMemo(() => {
    return filterImageElementIds(elements || []);
  }, [elements]);

  const handleGenerateImageUrls = useCallback(async (ids: string[]) => {
    if (ids.length) {
      const urls = await generateImageUrls({ imageElementIds: ids });

      setImageUrls(urls.data);
    }
  }, []);

  useEffect(() => {
    handleGenerateImageUrls(imageElementIds);
  }, [imageElementIds]);

  return imageUrls;
};

export default useImageUrls;
