import { FC } from 'react';
import { ITask } from 'api/digifi/TasksApi';
import Label from 'components/digifi-wrappers/Label/Label';
import SkeletonTextSection from 'components/PortalPageElements/TasksElement/sections/SkeletonTextSection';
import styles from 'components/PortalPageElements/TasksElement/popups/PopUps.module.scss';

interface IInstructionsSectionProps {
  task: ITask;
  loading?: boolean;
}

const InstructionsSection: FC<IInstructionsSectionProps> = ({ task, loading }) => {
  if (loading) {
    return (
      <SkeletonTextSection />
    );
  }

  return (
    <>
      <Label className={styles.sectionLabel}>
        Instructions
      </Label>
      <p className={styles.instructions}>
        {task.instructions ? task.instructions : (
          <p className={styles.noItemsMessage}>
            No instructions
          </p>
        )}
      </p>
    </>
  );
};

export default InstructionsSection;
