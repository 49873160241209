import { nanoid } from 'nanoid';
import clsx from 'clsx';
import { ITextColumnsElementConfig, TextColumnsBulletType } from 'api/digifi/portal-page-elements';
import Header from 'product_modules/components/Header';
import Bullet from './Bullet';
import styles from './TextColumnsElement.module.scss';

interface ITextColumnsElement {
  config: ITextColumnsElementConfig;
  containerClassName?: string;
}

const TextColumnsElement = ({ config, containerClassName }: ITextColumnsElement) => {
  return (
    <div className={clsx(styles.container, containerClassName)}>
      {config.columns.map((column, index) => (
        <div className={styles.columnContainer} key={nanoid()}>
          <Bullet
            index={index}
            bulletType={config.bulletType || TextColumnsBulletType.NoBullet}
          />
          <div className={styles.columnContentContainer}>
            <Header type="h6">
              {column.headerText}
            </Header>
            <p className={styles.columnBody}>
              {column.bodyText}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TextColumnsElement;
