import { FC, FocusEvent, useMemo, useRef } from 'react';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';
import { IESignatureElement, IOfferSelectionPageElement, PortalPageElementType } from 'api/digifi/portal-page-elements';
import PageElement from 'components/ApplicationPage/PageElement';
import { TableValue, VariableValue } from 'product_modules/api/Types';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import useApplicationSystemContext from 'hooks/system-contexts/useApplicationSystemContext';

interface IApplicationPage {
  className?: string;
  pageElements: IPortalPageElement[];
  application: IApplicationViewModel;
  isElementLoadingByType: Record<PortalPageElementType, boolean>;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur: (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onSubmitPageData: () => Promise<void>;
  onOfferSelect: (updatedOffers: TableValue) => void;
  offersVariableValue: TableValue | null;
  offersSelectionElement: IOfferSelectionPageElement | null;
  eSignatureElement: IESignatureElement | null;
  imageUrls?: Record<string, string>;
}

const ApplicationPage: FC<IApplicationPage> = ({
  pageElements,
  application,
  isElementLoadingByType,
  className,
  portalPageFormData,
  onPortalPageFieldChange,
  onPortalPageFieldBlur,
  onSubmitPageData,
  offersVariableValue,
  onOfferSelect,
  offersSelectionElement,
  eSignatureElement,
  imageUrls,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const systemInputsContext = useApplicationSystemContext(application);
  const customInputsContext = application.variables;

  const context = useMemo(() => {
    return { ...systemInputsContext, ...customInputsContext };
  }, [systemInputsContext, customInputsContext]);

  return (
    <div className={className} ref={containerRef}>
      {pageElements.map((element) => (
        <PageElement
          key={element.id}
          element={element}
          context={context}
          productId={application.productId}
          applicationDisplayId={application.displayId}
          isElementLoadingByType={isElementLoadingByType}
          portalPageFormData={portalPageFormData}
          onPortalPageFieldChange={onPortalPageFieldChange}
          onPortalPageFieldBlur={onPortalPageFieldBlur}
          onSubmitPageData={onSubmitPageData}
          offersVariableValue={offersVariableValue}
          onOfferSelect={onOfferSelect}
          offersSelectionElement={offersSelectionElement}
          eSignatureElement={eSignatureElement}
          containerRef={containerRef}
          imageUrls={imageUrls}
        />
      ))}
    </div>
  );
};

export default ApplicationPage;
